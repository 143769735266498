import React, { useEffect, useState } from "react";
import Layout from "../../layouts/Layout";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DraggableComponent } from "./draggable/DraggableComponent";
import { ButtonGroup } from "react-bootstrap";
import { toast, Slide } from "react-toastify";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";

const CardPage = () => {
  const navigate = useNavigate();
  const params = useParams();
  const { id } = params;
  const [boxes, setBoxes] = useState({
    a: {
      top: 150,
      left: 40,
      title: `Just a note to thank you
    and a line to tell you,
    too nothing was more welcomed
    than the lovely gift from yo`,
      color: "#000",
    },
    b: { top: 325, left: 110, title: "Drag me too", color: "#000" },
  });
  const [color, setColor] = useState("#fff");
  const [price, setPrice] = useState("");
  const [shape, setShape] = useState("");
  const [categoryData, setCategoryData] = useState([]);
  const [subCategoryData, setSubCategoryData] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedSubCategory, setSelectedSubCategory] = useState("");

  const [title, setTitle] = useState("");
  const [backgroundImage, setBackgroundImage] = useState("");
  const [url, setUrl] = useState("");

  const fetchSingleCardData = async () => {
    try {
      const response = await axios.get(
        `https://backend.canursvp.com/api/cards/get/${id}`
      );
      const data = response?.data?.data;
      setBoxes({
        a: {
          ...data?.text1,
        },
        b: { ...data?.text2 },
      });

      setTitle(data?.title);
      setBackgroundImage(data?.imgUrl);
      setUrl(data?.imgUrl);
      setPrice(data?.price);
      setColor(data?.color);
      setShape(data?.shape);
      setSelectedCategory(data?.categoryId);
      setSelectedSubCategory(data?.subCategoryId);

      // Handle the response if the request is successful
    } catch (error) {
      // Handle any errors that occur during the request
      console.error("Error fetching card data:", error.message);
    }
  };

  const fetchCategoryType = async () => {
    try {
      const { data } = await axios.get(`https://backend.canursvp.com/api/category`);

      let categories = [];
      let subCategories = [];

      data?.data.forEach((parent) => {
        // Push parent category to categories array
        categories.push({
          _id: parent._id,
          name: parent.categoryName,
        });

        // Push each subcategory along with its parent categoryId to subCategories array
        parent?.subcategories.forEach((child) => {
          subCategories.push({
            categoryId: parent._id, // Use the parent category _id
            name: child?.name,
            _id: child?._id,
          });
        });
      });

      // Update the state with the fetched categories and subcategories
      setCategoryData(categories);
      setSubCategoryData(subCategories);
    } catch (error) {
      // Handle any errors that occur during the request
      console.error("Error fetching category data:", error.message);
    }
  };

  useEffect(() => {
    fetchCategoryType();
    if (id !== "card") {
      fetchSingleCardData();
    }
  }, []);

  const uploadImage = (file) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("title", "images");

    axios
      .post(`https://backend.canursvp.com/api/images/upload`, formData)
      .then((res) => {
        setBackgroundImage(res?.data?.resp?.url);
        setUrl(res?.data?.resp?.url);
      })
      .catch(console.error);
  };

  const onSubmit = async () => {
    const cardData = {
      categoryId: selectedCategory,
      subCategoryId: selectedSubCategory,

      title,
      // categoryId: subCategoryData.find(
      //   (item) => item.categoryName === selectedCategory
      // )?._id,
      imgUrl: url,
      price,
      color,
      shape,
      text1: boxes.a,
      text2: boxes.b,
    };

    try {
      const { data } = await axios.post(
        `https://backend.canursvp.com/api/card/add`,
        cardData
      );
      toast.success("Card save successfully", {
        hideProgressBar: false,
        autoClose: 2000,
        transition: Slide,
        progress: undefined,
      });
      navigate("/cards");
    } catch (error) {
      console.error("Error fetching card data:", error.message);
    }
  };

  const onUpdate = async () => {
    const cardData = {
      id: id,
      title,
      categoryId: selectedCategory,
      subCategoryId: selectedSubCategory,
      imgUrl: url,
      price,
      color,
      shape,
      text1: boxes.a,
      text2: boxes.b,
    };

    try {
      const { data } = await axios.post(
        `https://backend.canursvp.com/api/card/update/${id}`,
        cardData
      );
      toast.success("Card updated successfully", {
        hideProgressBar: false,
        autoClose: 2000,
        transition: Slide,
        progress: undefined,
      });
      navigate("/cards");
    } catch (error) {
      console.error("Error fetching card data:", error.message);
    }
  };

  return (
    <Layout>
      <form noValidate>
        <div className="container">
          {/* Header */}
          <div className="d-flex flex-wrap gap-3 mb-4">
            <h3 className="mb-0 text-capitalize me-auto">Card</h3>
            <button
              onClick={id === "card" ? onSubmit : onUpdate}
              type="button"
              className="btn btn-primary"
            >
              Save
            </button>
          </div>
          {/* /Header */}
          <div className="row gy-4 pb-4 my-3 border shadow-sm rounded">
            <div className="col-12 col-lg-3 col-xl-2">
              <div className="h-100 p-2 border shadow-sm rounded">
                <ButtonGroup vertical className="w-100">
                  <div className="pb-2">
                    <label htmlFor="card-title">Add Title</label>
                    <input
                      type="text"
                      name="card-title"
                      className="form-control"
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                    />
                  </div>

                  <div className="pb-2">
                    <label htmlFor="category-select">Select Category</label>
                    <select
                      className="form-select"
                      value={selectedCategory}
                      onChange={(e) => setSelectedCategory(e.target.value)}
                    >
                      <option value="">Select</option>
                      {categoryData.map((category, index) => (
                        <option key={index} value={category?._id}>
                          {category?.name}
                        </option>
                      ))}
                    </select>
                  </div>

                  {selectedCategory && (
                    <div className="pb-2">
                      <label htmlFor="sub-category-select">
                        Select Sub Category
                      </label>

                      <select
                        className="form-select"
                        value={selectedSubCategory}
                        onChange={(e) => setSelectedSubCategory(e.target.value)}
                      >
                        <option value="">Select</option>
                        {subCategoryData
                          .filter(
                            (item) => item.categoryId === selectedCategory
                          )
                          .map((subCat, index) => (
                            <option key={index} value={subCat._id}>
                              {subCat.name}
                            </option>
                          ))}
                      </select>
                    </div>
                  )}

                  <div className="pb-2">
                    <label htmlFor="shape-select">Select Shape</label>
                    <select
                      className="form-select"
                      value={shape}
                      onChange={(e) => setShape(e.target.value)}
                    >
                      <option value="">Select</option>
                      <option value="square">Square</option>
                      <option value="rectangle">Rectangle</option>
                    </select>
                  </div>
                  <div className="pb-2">
                    <label htmlFor="card-price">Add Price</label>
                    <input
                      type="text"
                      name="card-price"
                      className="form-control"
                      value={price}
                      onChange={(e) => setPrice(e.target.value)}
                    />
                  </div>
                  <button
                    type="button"
                    className="btn btn-outline-primary w-100 position-relative"
                  >
                    <label htmlFor="color-picker">Change BG Color</label>
                    <input
                      type="color"
                      name="color-picker"
                      className="position-absolute opacity-0 h-100 w-100 start-0 top-0 btn p-0"
                      value={color}
                      onChange={(e) => setColor(e.target.value)}
                    />
                  </button>
                  <button
                    type="button"
                    className="btn btn-outline-primary w-100 position-relative"
                  >
                    <label htmlFor="image-uploader">
                      Add a Background Image
                    </label>
                    <input
                      type="file"
                      name="image-uploader"
                      className="position-absolute opacity-0 h-100 w-100 start-0 top-0 btn p-0"
                      accept="image/*"
                      onChange={(e) => uploadImage(e.target.files[0])}
                    />
                  </button>
                </ButtonGroup>
              </div>
            </div>

            <div className="col">
              <DndProvider backend={HTML5Backend}>
                <DraggableComponent
                  hideSourceOnDrag={true}
                  imageUrl={backgroundImage}
                  bgColor={color}
                  boxes={boxes}
                  setBoxes={setBoxes}
                />
              </DndProvider>
            </div>
          </div>
        </div>
      </form>
    </Layout>
  );
};

export default CardPage;
